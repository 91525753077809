import PropTypes from 'prop-types'
import React, {useMemo} from 'react'

import {
    Box,
    Flex,
    Heading,
    Skeleton,
    Text,
    useMediaQuery,
    useMultiStyleConfig
} from '@salesforce/retail-react-app/app/components/shared/ui'
import _ from 'lodash'

import {colors, styleConfigChakraKeys} from '../../constants'
import Link from '../link'
import CTASection from './cta-section'
import HeroImageContainer from './hero-image-container'
import {contentfulMediaFormatter} from '../../utils/contentful'

const HeroSection = ({data, headingProps = {}, ...otherProps}) => {
    const [isDesktop] = useMediaQuery('(min-width: 992px)')
    const styles = useMultiStyleConfig(styleConfigChakraKeys.heroSection)
    const heading = data?.heading
    const subheading = data?.subTitle
    const ctaTop = data?.ctaTop
    const ctaBottom = data?.ctaBottom
    const ctaItems = _.filter([ctaTop, ctaBottom])
    const mediaAlignment = data?.mediaAlignment
    const sectionName = data?.sectionName

    const asset = useMemo(() => {
        const media = data?.media?.fields
            ? contentfulMediaFormatter(data?.media)
            : data?.media

        return !isDesktop && media?.mobile?.url ? media?.mobile : media?.desktop
    }, [data, isDesktop])

    return !data ? (
        <Flex
            margin="3%"
            alignItems="center"
            flexDirection={{base: 'column-reverse', lg: 'row'}}
            gap="30px"
            width="100%"
        >
            <Flex
                gap="20px"
                alignItems="start"
                justifyContent="flex-end"
                flexDirection="column"
                width={{base: '100%', lg: '40%'}}
            >
                <Skeleton height={{base: '80px', lg: '100px'}} width="80%" />
                <Skeleton height={{base: '60px', lg: '90px'}} width="90%" />
                <Skeleton height={{base: '60px', lg: '90px'}} width="90%" />
            </Flex>
            <Skeleton
                height={{base: '300px', lg: '600px'}}
                width={{base: '100%', lg: '55%'}}
            />
        </Flex>
    ) : (
        <>
            {mediaAlignment == 'overlap' ? (
                <Flex
                    {...(sectionName && {id: sectionName})}
                    flexDirection="column"
                    gap={{base: '3px', md: '10px', lg: '18px'}}
                    height={{base: '367px', md: '650px', lg: '730px'}}
                    backgroundImage={{
                        base: `url(${asset?.url})`,
                        md: `url(${asset?.url})`
                    }}
                    color={colors.white}
                    padding={{base: '197px 16px', lg: '328px 68px'}}
                    backgroundRepeat="no-repeat"
                    backgroundSize="cover"
                    backgroundPosition={{base: 'top', lg: 'center'}}
                    {...otherProps}
                >
                    <Heading
                        color={colors.white}
                        fontSize={{base: '32px', md: '48px', lg: '64px'}}
                        lineHeight={{base: '35px', md: '45px', lg: '58px'}}
                        fontFamily="Raleway"
                        fontWeight="300"
                        {...headingProps}
                        dangerouslySetInnerHTML={{
                            __html: heading
                        }}
                    />
                    <Text
                        fontFamily="Raleway"
                        color={colors.white}
                        fontSize={{base: '15px', md: '24px', lg: '32px'}}
                        fontWeight="300"
                    >
                        {subheading}
                    </Text>
                    <Box maxWIdth="883px">
                        {ctaItems?.map((cta, index) => {
                            const _cta = cta?.fields ?? cta
                            const actionType = _cta?.actionType

                            let ctaAction = _cta
                            if (actionType && actionType === 'Book service') {
                                ctaAction = {
                                    ..._cta,
                                    url: null,
                                    externalUrl: false
                                }
                            }

                            return (
                                <Link
                                    {...ctaAction}
                                    marginTop={{
                                        base: '2px',
                                        md: '5px',
                                        lg: '7px'
                                    }}
                                    key={index}
                                    buttonStyles={{
                                        color: colors.white,
                                        textDecoration: 'underline',
                                        fontSize: {base: '16px', lg: '25px'},
                                        fontFamily: 'Raleway'
                                    }}
                                    _hover={{
                                        svg: {opacity: '1'},
                                        background: 'transparent',
                                        backgroundColor: 'transparent'
                                    }}
                                />
                            )
                        })}
                    </Box>
                </Flex>
            ) : (
                <Flex
                    {...(sectionName && {id: sectionName})}
                    {...styles.container}
                    {...otherProps}
                >
                    {mediaAlignment === 'left' ? (
                        <>
                            <HeroImageContainer
                                media={asset}
                                autoplayVideo={true}
                            />
                            <CTASection heading={heading} ctaItems={ctaItems} />
                        </>
                    ) : (
                        <>
                            <CTASection heading={heading} ctaItems={ctaItems} />
                            <HeroImageContainer
                                media={asset}
                                autoplayVideo={true}
                            />
                        </>
                    )}
                </Flex>
            )}
        </>
    )
}

HeroSection.propTypes = {
    data: PropTypes.shape({
        heading: PropTypes.string,
        subTitle: PropTypes.string,
        ctaTop: PropTypes.string,
        ctaBottom: PropTypes.string,
        sectionName: PropTypes.string,
        media: PropTypes.shape({
            mobile: PropTypes.shape({url: PropTypes.string}),
            desktop: PropTypes.shape({url: PropTypes.string})
        }),
        mediaAlignment: PropTypes.string,
        homeHeroSectionCollection: PropTypes.shape({
            items: PropTypes.arrayOf(
                PropTypes.shape({
                    heading: PropTypes.string,
                    ctaTop: PropTypes.shape({
                        text: PropTypes.string,
                        url: PropTypes.string
                    }),
                    ctaBottom: PropTypes.shape({
                        text: PropTypes.string,
                        url: PropTypes.string
                    }),
                    media: PropTypes.shape({
                        desktop: PropTypes.shape({
                            url: PropTypes.string,
                            type: PropTypes.string
                        })
                    }),
                    mediaAlignment: PropTypes.oneOf(['left', 'right']),
                    numberOfColumns: PropTypes.string
                })
            )
        })
    }),
    headingProps: PropTypes.object
}

export default HeroSection
